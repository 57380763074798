<template>
  <div class="m-0 p-0">
    <div class="row m-0 p-0">
      <div class="col-4 col-md col-lg mt-1 px-0 px-md-1">
        <button
          @click="setselect(1), setdatareturn()"
          type="button"
          :class="select.all == 1 ? 'bt1' : 'bt2'"
          class="m-0 p-0 btn w-100 fontsize"
        >
          All
        </button>
      </div>
      <div class="col-4 col-md col-lg mt-1 px-0 px-md-1">
        <button
          :class="select.ofen == 1 ? 'bt1' : 'bt2'"
          @click="setselect(2), setdatareturn()"
          type="button"
          class="m-0 p-0 btn w-100 fontsize"
        >
          use often
        </button>
      </div>
      <div class="col-4 col-md col-lg mt-1 px-0 px-md-1">
        <button
          :class="select.like == 1 ? 'bt1' : 'bt2'"
          @click="setselect(3), setdatareturn()"
          type="button"
          class="m-0 p-0 btn w-100 fontsize"
        >
          Like
        </button>
      </div>
      <div class="col-12 col-lg-8 col-md-8 mt-1 mt-md-0 mx-0 px-0">
        <div class="with-icon">
          <span class="color-light"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-search"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg
          ></span>
          <input
            v-on:keyup.enter="setdatareturn()"
            type="search"
            v-model="Search"
            class="form-control ih-medium ip-lightradius-xs b-light"
            placeholder="Search"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RightHeaderSearch",
  data() {
    return {
      Search: "",
      select: { all: 1, ofen: 0, like: 0 },
      valuebutton: 0,
    };
  },
  mounted() {
     this.setdatareturn();
  },
  methods: {
    setselect(key) {
      if (key == 1) {
        this.select = { all: 1, ofen: 0, like: 0 };
        this.valuebutton = 0;
      } else if (key == 2) {
        this.select = { all: 0, ofen: 1, like: 0 };
        this.valuebutton = 1;
      } else if (key == 3) {
        this.select = { all: 0, ofen: 0, like: 1 };
        this.valuebutton = 2;
      }
    },
    setdatareturn() {
      let data = { btn: this.valuebutton, input: this.Search };
      this.$emit("common_btn_search", data);
    },
  },
};
</script>

<style scoped>
.bt1 {
  background: #64748b;
  border-radius: 50px;
  color: white;
}
.bt2 {
  background: #f1f5f9;
  border-radius: 50px;
  color: black;
}
.fontsize {
  font-size: 0.75em;
}
</style>